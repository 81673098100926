const reportsEN = {
  // namespace your translation keys with "rpt", i.e.
  // 'report.lorem_ipsum': 'Lorem Ipsum'
  'report.by_bill_time': 'By Bill Time',
  'report.by_sales': 'By Sales',
  'report.by_close_time': 'Bill Close time',
  'report.by_start_time': 'Bill Start time',
  'report.by_sent_time': 'Bill Item Sent time',
  'report.net_sales': 'Net sales',
  'report.gross_sales': 'Gross sales',
  'report.step': 'Step',
  'report.report_format_desc':
    'Please note that you will receive one .csv file for each report or one .xlsx file with multiple sheets (one sheet for each report)',
  'report.scheduled_report_emails_desc': 'You can enter multiple e-mails separated by a comma',
  'report.daily-tab': 'Daily Sales',
  'report.hourly-tab': 'Hourly Sales',
  'report.discounts': 'Discounts',
  'report.voids': 'Voids',
  'report.weekly': 'Weekly',
  'report.monthly': 'Monthly',
  'report.choose_scheduled_multi':
    'Please choose the reports you want to include in your scheduled report.',
  'report.select_date': 'Select Start date',
  'report.filter_by_date': 'Filter by Date:',
  'report.filter_by_status': 'Filter by Status:',
  'report.filter_by_channel': 'Filter by Channel:',
  'report.continue_scheduling': 'Continue Scheduling',
  'report.will_not_be_scheduled_update':
    'Please note that your changes will not be saved and you will have to start over the next time.',
  'report.not_scheduled_yet_confirm_update':
    'Your changes have not been saved yet. Are you sure you want to go back?',
  'report.not_scheduled_yet_confirm_create':
    'Your report has not been scheduled yet. Are you sure you want to go back?',
  'report.will_not_be_scheduled_create':
    'Please note that your report will not be scheduled and you will lose any progress you have made.',
  'report.success': 'Success!',
  'report.has_been_scheduled': 'Your report has been scheduled',
  'report.schedule_report_title_required': 'Please enter a title',
  'report.send_to': 'Send to',
  'report.select_at_least_one_venue': 'Please select at least one venue',
  'report.select_at_least_one_report': 'Please select at least one report',
  'report.invalid_email': 'Please enter a valid email address',
  'report.duplicate_email': 'Please remove email duplicates',
  'report.email_add_required': 'Please enter at least one email address',
  'report.validation_required_min': 'Please select at least one section',
  'report.validation_required': 'This field is required',
  'report.select_venues': 'Select Venues',
  'report.warning_deleting_scheduled_report_sub':
    'This will permanently delete your report. You cannot undo this action.',
  'report.schedule_report_freq_daily': 'You will receive your report every day.',
  'report.schedule_report_freq_weekly':
    'You will receive your report every week. Your report will consolidate information from the start to the end of your previous service week.',
  'report.schedule_report_freq_2weeks':
    'You will receive your report every 2 weeks. You can select the start date (any day in the past 2 weeks before today) and your report will consolidate information from the start date to 2 weeks from then.',
  'report.schedule_report_freq_monthly':
    'You will receive your report on the first day of each month. Your report will consolidate information from the start to the end of the previous month.',
  'report.next_report_date_column': 'Next Report Date (Y/M/D)',
  'report.next_report_date': 'Next Report date:',
  'report.merging_report_title':
    '"{{reportName}}" and "{{reportNameTwo}}" reports will be merged together into a new report "{{mergeReportName}}".',
  'report.merging_report_merge_body':
    'In the next few weeks, we will be combining the "{{reportName}}" and "{{reportNameTwo}}" reports into one single report called "{{mergeReportName}}". This new report can be found under Reports > {{mergeSectionName}} and you will be able to download it in CSV or XLS format. If you have previously scheduled to receive both of these reports, note that you will receive a single report moving forward.',
  'report.reopened_bills': 'Reopened Bills',
  'report.gift_card_analysis': 'Gift Card Analysis',
  'report.sales': 'Sales',
  'report.activity': 'Activity',
  'report.takeout_types': 'Takeout Types',
  'report.deleted_items': 'Deleted Items',
  'report.tax_exclusion': 'Tax Exclusion',
  'report.no_sale': 'No Sale',
  'report.name_scheduled_report': 'Name Scheduled Report',
  'report.indicates_required_field': 'indicates a required field.',
  'report.server': 'Server',
  'report.date_and_timestamp': 'Date/Timestamp',
  'report.reason': 'Reason',
  'report.amount': 'Amount',
  'report.register': 'Register',
  'report.comment': 'Comment',
  'report.accounts': 'Accounts',
  'report.accounts_deposits_payments': 'Show Deposits/Payments',
  'report.payins': 'PayIns',
  'report.payouts': 'PayOuts',
  'report.payins_payouts': 'PayOuts/PayIns',
  'report.updated_at': 'Last Updated On',
  'report.report_merged_into_staff_performance':
    'This report has been merged with the Staff Performance report and moved to the Labor section',
  'report.moved_to_other_section': 'This report has moved to the {{section}} Section',
  'report.detailed_sales': 'Detailed Sales',
  'report.decommission_title': '{{currentName}} will be merge with report {{newReportName}}',
  'report.decommission_body':
    '{{currentName}} report has been merged into the "{{newReportName}}" report under {{newSection}}. You will be able to download the report in your preferred format.',
  'report.single_vs_multi_selection':
    'Available reports will change based on the number of venues selected; changing the number of venues selected after choosing reports will clear the report selection and you will have to select your report(s) again.',
  'report.print_this_report': 'Print this report',
  'report.report_range': 'Report Range',
  'report.number_of_venues': '# of Venues',
  'report.venues_included': 'Venues Included',
  'report.report_summary': 'Report Summary',
  'report.incompatible_browser_title': 'Incompatible browser',
  'report.incompatible_browser_body':
    'We recommend using Google Chrome or Mozilla Firefox for printing reports.',
  'report.incompatible_browser_button': 'Print Anyway',
  'report.printing_in_progress': 'Preparing your report...',
  'report.printing_disclaimer':
    'Please note that large volumes of data may take longer to print. We recommend printing reports with lower volumes of data to ensure successful printing.',
  'report.send_report': 'Send Report',
  'report.send_reports': 'Send Reports',
  'report.send_report_desc':
    'Please select the report format and enter the email addresses you would like to send the report to.',
  'report.report_format': 'Report Format',
  'report.success_sent': 'Your report has been sent.',
  'report.error_sent': 'Your report could not be sent. Please try again.',
  'report.dashboard_top_sales_categories': 'Top Sales Categories',
  'report.dashboard_top_shifts': 'Top Shifts',
  'report.dashboard_tender_types': 'Tender Types',
  'report.dashboard_top_menu_groups': 'Top Menu Groups',
  'report.dashboard_top_discounts_voids': 'Top Discounts & Voids',
  'report.dashboard_top_menu_items': 'Top Menu Items',
  'report.no_bills_found': 'No Bills found',
  'report.reports': 'Reports',
  'report.select_other_reports': 'Select Other Report(s)',
  'report.pos_to_cloud_reports_limited_data':
    "Update to TouchBistro POS version 10.4 or above to access this report. Once updated, you'll have access to data from that date onwards until historical data is added.",
  'report.pos_to_cloud_reports_limited_data_gift_card':
    "Update to TouchBistro POS version 10.7 or above to access this report. Once updated, you'll have access to data from that date onwards until historical data is added.",
  'report.week_selector': 'Week Selector',
  'report.month_selector': 'Month Selector',
  'report.staff_summary': 'Staff Summary',
  'report.continue_editing': 'Continue Editing',
  'report.configurable_reports': 'Configurable Reports',
  'report.view-reports': 'View Reports',
  'report.view-reports-description':
    'Select a category and then a report you’d like to view. Customize and save your new report to quickly access them for future use.',
  'report.saved-reports': 'Saved Reports',
  'report.no_saved_reports_title': 'No Saved Reports',
  'report.no_saved_reports_message':
    'You currently don’t have any saved reports. View, customize and save reports to access them here.',
  'report.description': 'Description',
  'report.category': 'Categories',
  'report.created_at': 'Created',
  'report.select_a_category': 'Select a Category',
  'report.saved_report_desc': 'View, edit or favorite your saved reports.',
  'report.basic_list_view_update_prompt': 'Are you sure you want to update {{candidateForUpdate}}?',
  'report.warning_favorite_report': 'This action will set your report as favorite.',
  'report.end_of_day': 'End Of Day',
  'report.update_custom_report_error':
    'There was an error while updating your report. Please try again.',
  'report.report_updated': 'Report updated',
  'report.all_reports': 'All Reports',
  'report.print': 'Print',
  'report.select_reports': 'Select Reports',
  'report.end_of_day_report': 'End of Day Report',
  'report.shift_report': 'Shift Report',
  'report.no_data': 'No data available',
  'report.all_selected': 'All selected',
  'report.edit_columns': 'Edit Columns',
  'report.save_report_as': 'Save Report As',
  'report.new_custom_report': 'New Custom Report',
  'report.update_custom_report': 'Update Current Report',
  'report.saved_report_name': 'Name your report',
  'report.optional_description': 'Add a note (optional)',
  'report.save_report_title': 'Save Report',
  'report.report_update_success_notification': '{{reportName}} Report has been updated',
  'report.day_part_name': 'Day Part Name',
  'report.start_and_end_time': 'Start and End Time',
  'report.scheduled_days': 'Scheduled Days',
  'report.no_data_for_shift': 'No Data for {{date}}',
  'report.no_data_for_shift_to_show_data':
    'To show the data for your shift report, please select the dates you clocked-in or clocked-out. Any dates in-between won’t display any data.',
  'report.no_data_for_shift_reminder':
    'A reminder to please clock-out to avoid shifts spanning multiple days.',
  'report.eod_report.sales-tax-and-tip-summary': 'Sales, Tax, and Tip Summary',
  'report.eod_report.sales-summary': 'Detailed Sales Summary',
  'report.eod_report.sales-by-category': 'Sales By Sales Category',
  'report.eod_report.sales-by-section': 'Sales By Section',
  'report.eod_report.gift-card-sales': 'Gift Card Sales',
  'report.eod_report.labor-summary': 'Detailed Labor Summary',
  'report.eod_report.tips-by-employee': 'Tips By Employee',
  'report.eod_report.tips-summary': 'Tips Summary',
  'report.eod_report.payments-summary': 'Payments Summary',
  'report.eod_report.payment-by-payment-type': 'Payments By Payment Type',
  'report.eod_report.tip-outs-summary': 'Tip Outs',
  'report.eod_report.accounts': 'Accounts Summary',
  'report.eod_report.payouts-payins': 'Payouts/Payins',
  'report.eod_report.cash-deposit': 'Cash Deposit',
  'report.generating_report': 'Generating Your Report',
  'report.generating_report_message':
    'Please wait a moment while we generate your report to download',
  'report.purchases': 'Purchases',
  'report.explain_this_report': 'Explain This Report',
  'report.advanced_filters': 'Advanced Filters',
  'report.selected_filters': 'Selected Filters',
  'report.all_statuses': 'All Statuses',
  'report.all_channels': 'All Channels',
  'report.deposits': 'Deposits',
  'report.table.date_time': 'DATE/TIME',
  'report.table.status': 'STATUS',
  'report.table.reasons': 'REASONS',
  'report.table.total_amount': 'TOTAL AMOUNT',
  'report.table.tip_amount': 'TIP AMOUNT',
  'report.table.fees': 'FEES',
  'report.table.network_state': 'NETWORK STATE',
  'report.table.card_type': 'CARD TYPE',
  'report.table.card_number': 'CARD NUMBER',
  'report.table.bill_number': 'BILL NUMBER',
  'report.table.order_number': 'ORDER NUMBER',
  'report.table.transaction_id': 'TRANSACTION ID',
  'report.table.auth_id': 'AUTH ID',
}

export default reportsEN
