const paymentsEN = {
  'non_integrated.title': 'Non-Integrated Payments',
  'non_integrated.enable_section_title': 'Enable Non-Integrated Payments',
  'non_integrated.enable_section_toggle_description':
    'Include Non-Integrated Payment options on the POS during checkout',
  'non_integrated.types_section_title': 'Non-Integrated Payment Types',
  'non_integrated.types_section_description':
    'Configure payment types that will be used when recording Non-Integrated Payments on your POS',
  'non_integrated.types_section_add_button_label': 'Create New Payment Type',
  'non_integrated.types_empty_title': 'There are no Non Integrated Payments to be displayed',
  'non_integrated.validation_error_length': 'Name needs to be under 25 characters.',
  'non_integrated.validation_error_cash_name':
    '“Cash” is already a default payment method. Using it as a Non-Integrated payment type will cause reporting inconsistencies.',
  'non_integrated.validation_duplicate_name': 'Name is already in use.',
  'non_integrated.create_modal_title': 'Create New Payment Type',
  'non_integrated.input_name_label': 'Name',
  'non_integrated.input_helper_text': 'This name will be displayed on the POS and on receipts',
  'non_integrated.create_modal_cancel_button_label': 'Cancel',
  'non_integrated.create_modal_save_button_label': 'Add Payment Type',
  'non_integrated.alert_added_message': 'Payment type added',
  'non_integrated.alert_saved_message': 'Saved successfully',
  'non_integrated.alert_error_message':
    'An error occurred while saving the payment type. {{reason}}',
  'non_integrated.create_modal_update_button_label': 'Update Payment Type',
  'non_integrated.edit_modal_title': 'Edit Payment Type',

  'non_integrated.delete_modal_title': 'Are you sure you want to delete this Payment Type?',
  'non_integrated.delete_modal_confirm_button_label': 'Confirm',
  'non_integrated.delete_modal_cancel_button_label': 'Cancel',
  'non_integrated.alert_deleted_message': 'Payment type deleted',
}

export default paymentsEN
