const staffEN = {
  'stf.loyalty_groups': 'Guest Engagement Groups',
  'stf.select_groups': 'Select Groups',
  'stf.role_information': 'Role Information',
  'stf.permissions.main_title': 'Permissions',
  'stf.permissions.role_name_text': 'Role Name',
  'stf.permissions.role_description_text': 'Role Description',

  // POS
  'stf.permissions.pos': 'POS',
  'stf.permissions.pos.restart': 'Can Start Day (Restarting the Android app)',
  'stf.permissions.pos.launchTouchBistro': 'Can Action Update (within TouchBistro)',
  'stf.permissions.pos.clockInOnly': 'Clock-In / Clock Out Only',
  'stf.permissions.order.void': 'Can Authorize and Perform Voids without Approval',
  'stf.permissions.order.discount': 'Can Authorize and Perform Discounts without Approval',
  'stf.permissions.order.gratuity': 'Can Authorize and Add Gratuity',
  'stf.permissions.cashdrawer.open': 'Can Open Cash Drawer',
  'stf.permissions.order.refund': 'Can Refund Closed Bills',
  'stf.permissions.cashdrawer.payInOut': 'Can Perform Pay-Ins/Pay-Outs',
  'stf.permissions.pos.accessTableIfSameRole': 'Can Access Other Staff Tables without Approval',
  'stf.permissions.pos.accessTakeout': 'Can Access Takeout / Delivery',
  'stf.permissions.pos.enableFastBar': 'Enable Fast Bar On Point of Sale',
  'stf.permissions.pos.requiresCashTip': 'Required Recording of Cash Tips',
  'stf.permissions.pos.accessCashRegister': 'Can Access Cash Registers on Floor Plan',
  'stf.permissions.pos.accessCustomerAccounts': 'Can Access Accounts',
  'stf.permissions.pos.enablePersonalRegister': 'Can Access Personal Register',
  'stf.permissions.pos.managePaymentGateways': 'Can Manage Payment Gateways',
  'stf.permissions.pos.manageOrders': 'Can Manage Orders',
  'stf.permissions.pos.performEndOfDay': 'Can Perform EOD',
  'stf.permissions.pos.admin': 'POS Administrative Access',
  'stf.permissions.report.endOfShift': 'Show Current and Past Shifts',
  'stf.permissions.autoProcessOrder.read': 'Can View an Auto Processed Order',
  'stf.permissions.autoProcessOrder.update': 'Can Update an Auto Processed Order',
  'stf.permissions.autoProcessOrder.delete': 'Can Delete an Auto Processed Order',
  'stf.permissions.order.take': 'Can Take Orders (Android only)',
  'stf.permissions.partners.overrideShift': 'Allow Shift Overrides with Partner Integrations',

  // Cash Management
  'stf.permissions.report.manageDepositsBlind': 'Can Create / Edit Blind Cash Deposits',
  'stf.permissions.report.manageDepositsFull': 'Can Create / Edit Full Cash Deposits',

  // Staff
  'stf.permissions.staff.section_title': 'Staff',
  'stf.permissions.staff': 'Can Manage Staff',
  'stf.permissions.staff.create': 'Add a User',
  'stf.permissions.staff.update': 'Edit a User',
  'stf.permissions.staff.delete': 'Delete a User',
  'stf.permissions.staff.read': 'View Roles',

  'stf.permissions.roles': 'Can Manage Roles',
  'stf.permissions.roles.create': 'Add a Role',
  'stf.permissions.roles.update': 'Edit a Role',
  'stf.permissions.roles.delete': 'Delete a Role',

  'stf.permissions.shifts.manage': 'Can Manage Shifts',

  'stf.permissions.overtimeRules.manage': 'Can Manage Overtime Rules',

  // Devices
  'stf.permissions.device.section_title': 'Devices',

  'stf.permissions.device': 'Can Manage Devices',
  'stf.permissions.device.read': 'Access a Device',
  'stf.permissions.device.update': 'Edit a Device',

  'stf.permissions.printer': 'Can Manage Printers',
  'stf.permissions.printer.read': 'Access a Printer',
  'stf.permissions.printer.create': 'Add a Printer',
  'stf.permissions.printer.update': 'Edit a Printer',
  'stf.permissions.printer.delete': 'Remove a Printer',

  'stf.permissions.pos.manage': 'POS Manage - Other Devices',

  // Menu
  'stf.permissions.menu.section_title': 'Menu',

  'stf.permissions.operationalMenu.publish': 'Menu Publishing',
  'stf.permissions.operationalMenu.readHistory': 'Menu Read History',

  'stf.permissions.menu': 'Can Manage Menu Settings',
  'stf.permissions.menu.read': 'View a Menu',
  'stf.permissions.menu.create': 'Create a Menu',
  'stf.permissions.menu.update': 'Update a Menu',
  'stf.permissions.menu.delete': 'Delete a Menu',

  'stf.permissions.menuEntityHistory.read': 'Menu Read Entity History',

  'stf.permissions.menuGroup': 'Can Manage Menu Groups',
  'stf.permissions.menuGroup.read': 'View a Menu Group',
  'stf.permissions.menuGroup.create': 'Create a Menu Group',
  'stf.permissions.menuGroup.update': 'Update a Menu Group',
  'stf.permissions.menuGroup.delete': 'Delete a Menu Group',

  'stf.permissions.menuItem': 'Can Manage Menu Items',
  'stf.permissions.menuItem.read': 'View a Menu Item',
  'stf.permissions.menuItem.create': 'Create a Menu Item',
  'stf.permissions.menuItem.update': 'Update a Menu Item',
  'stf.permissions.menuItem.delete': 'Delete a Menu Item',

  'stf.permissions.modifierGroup': 'Can Manage Modifier Groups',
  'stf.permissions.modifierGroup.read': 'View a Modifier Group',
  'stf.permissions.modifierGroup.create': 'Create a Modifier Group',
  'stf.permissions.modifierGroup.update': 'Update a Modifier Group',
  'stf.permissions.modifierGroup.delete': 'Delete a Modifier Group',

  'stf.permissions.modifier': 'Can Manage Modifiers',
  'stf.permissions.modifier.read': 'View a Modifiers',
  'stf.permissions.modifier.create': 'Create a Modifiers',
  'stf.permissions.modifier.update': 'Update a Modifiers',
  'stf.permissions.modifier.delete': 'Delete a Modifiers',

  'stf.permissions.salesCategory': 'Can Manage Sales Categories',
  'stf.permissions.salesCategory.read': 'View a Sales Category',
  'stf.permissions.salesCategory.create': 'Create a Sales Category',
  'stf.permissions.salesCategory.update': 'Update a Sales Category',
  'stf.permissions.salesCategory.delete': 'Delete a Sales Category',

  'stf.permissions.bulkMenu': 'Can Manage Bulk Menu Editing',
  'stf.permissions.bulkMenu.create': 'Create a Bulk Menu',
  'stf.permissions.bulkMenu.delete': 'Delete a Bulk Menu',

  'stf.permissions.image': 'Can Manage Menu Images',
  'stf.permissions.image.create': 'Create a menu image',
  'stf.permissions.image.read': 'View a menu image',
  'stf.permissions.image.delete': 'Delete a menu image',

  'stf.permissions.scheduledPublication': 'Can Manage Scheduling Publications',
  'stf.permissions.scheduledPublication.update': 'Update a Scheduling Publication',
  'stf.permissions.scheduledPublication.read': 'View a Scheduling Publication',
  'stf.permissions.scheduledPublication.delete': 'Delete a Scheduling Publication',

  'stf.permissions.promotion': 'Can Manage Promotional Pricing',
  'stf.permissions.promotion.create': 'Create a Promotional Pricing',
  'stf.permissions.promotion.update': 'Update a Promotional Pricing',
  'stf.permissions.promotion.read': 'View a Promotional Pricing',
  'stf.permissions.promotion.delete': 'Delete a Promotional Pricing',

  'stf.permissions.voidReason': 'Can Manage Voids',
  'stf.permissions.voidReason.read': 'Access a Void',
  'stf.permissions.voidReason.create': 'Create a Void',
  'stf.permissions.voidReason.update': 'Update a Void',
  'stf.permissions.voidReason.delete': 'Remove a Void',

  'stf.permissions.discount': 'Can Manage Discounts',
  'stf.permissions.discount.read': 'Access a Discount',
  'stf.permissions.discount.create': 'Create a Discount',
  'stf.permissions.discount.update': 'Update a Discount',
  'stf.permissions.discount.delete': 'Remove a Discount',

  'stf.permissions.stockAvailability': 'Can Manage Stock Availability',

  'stf.permissions.menuEntryHistory.read': 'Can View Menu Entry History',

  // Online Ordering

  // Reports
  'stf.permissions.reports.section_title': 'Reports',
  'stf.permissions.reports.cash_deposit': 'Cash Deposit',
  'stf.permissions.reports': 'Can Access Reports',
  'stf.permissions.report.read': 'Can Access Reports',
  'stf.permissions.eodreport.read': 'Can Access End of Day Report',

  // Floor Plan
  'stf.permissions.floorplan.section_title': 'Floor Plan',

  'stf.permissions.floorplan': 'Can Manage Floor Plan',
  'stf.permissions.floorplan.read': 'Read Floor Plan',
  'stf.permissions.floorplan.create': 'Create a Floor Plan',
  'stf.permissions.floorplan.update': 'Update a Floor Plan',
  'stf.permissions.floorplan.delete': 'Delete a Floor Plan',

  // Guest Engagement
  'stf.permissions.guest_engagement.section_title': 'Guest Engagement',

  'stf.permissions.guestEngagement': 'Can Manage Rewards',
  'stf.permissions.guestEngagement.read': 'Access a Reward',
  'stf.permissions.guestEngagement.create': 'Create a Reward',
  'stf.permissions.guestEngagement.update': 'Update a Reward',
  'stf.permissions.guestEngagement.delete': 'Delete a Reward',

  // Reservations
  'stf.permissions.reservations.section_title': 'Reservations',
  'stf.permissions.reservations.allSettings': 'Can Manage All Reservation Settings',
  'stf.permissions.reservations.manageFOH': 'Can Manage FOH Reservation Activity',

  'stf.permissions.reservationSetting': 'Can Manage Reservations',
  'stf.permissions.reservationSetting.create': 'Create a Reservation',
  'stf.permissions.reservationSetting.read': 'Access a Reservation',
  'stf.permissions.reservationSetting.update': 'Update a Reservation',
  'stf.permissions.reservationSetting.delete': 'Remove a Reservation',

  // Settings
  'stf.permissions.settings.section_title': 'Manage Settings & Venue Info',

  'stf.permissions.venue.update': 'Can Manage Venue Details',
  'stf.permissions.businessday.manage': 'Can manage Business Day',

  'stf.permissions.deliveryBounds': 'Can Manage Online Ordering Settings',
  'stf.permissions.deliveryBounds.create': 'Create a delivery boundary',
  'stf.permissions.deliveryBounds.read': 'Read a delivery boundary',
  'stf.permissions.deliveryBounds.update': 'Update a delivery boundary',
  'stf.permissions.deliveryBounds.delete': 'Delete a delivery boundary',
  'stf.permissions.serviceCharge': 'Can Manage Service Charges',
  'stf.permissions.serviceCharge.create': 'Create a service charge',
  'stf.permissions.serviceCharge.read': 'Read a service charge',
  'stf.permissions.serviceCharge.update': 'Update a service charge',
  'stf.permissions.serviceCharge.delete': 'Delete a service charge',

  'stf.permissions.tax': 'Can Manage Taxes',
  'stf.permissions.tax.read': 'Access a Tax',
  'stf.permissions.tax.create': 'Create a Tax',
  'stf.permissions.tax.update': 'Update a Tax',
  'stf.permissions.tax.delete': 'Delete a Tax',

  'stf.permissions.branding': 'Can Manage Branding',
  'stf.permissions.branding.create': 'Create a Brand',
  'stf.permissions.branding.read': 'Access a Brand',
  'stf.permissions.branding.update': 'Update a Brand',
  'stf.permissions.branding.delete': 'Remove a Brand',

  'stf.permissions.hoursAndScheduling.update': 'Can Manage Hours & Scheduling',
  'stf.permissions.hoursAndScheduling.read': 'Can Read Hours & Scheduling',
  'stf.permissions.venueInfoDayParts.update': 'Can Manage Day Parts',
  'stf.permissions.venueInfoDayParts.read': 'Can Read Day Parts',
  'stf.permissions.venueInfoOperatingHours.update': 'Can Manage Operating Hours',
  'stf.permissions.venueInfoOperatingHours.read': 'Can Read Operating Hours',
  'stf.permissions.venueInfoServiceTime.update': 'Can Manage Service Time',
  'stf.permissions.venueInfoServiceTime.read': 'Can Read Service Time',

  // Payments Portal
  'stf.permissions.payments_portal.section_title': 'Payments Portal',

  'stf.permissions.payments': 'Access to CS Portal (TB Payments)',
  'stf.permissions.payments.create': 'Create Payments',
  'stf.permissions.payments.delete': 'Remove Payments',
  'stf.permissions.payments.read': 'Access Payments',
  'stf.permissions.payments.update': 'Update Payments',

  // App Marketplace
  'stf.permissions.marketplace.section_title': 'App Marketplace',
  'stf.permissions.marketplace.manage': 'Can Manage App Marketplace',

  // Service Charges
  'stf.permissions.pos.authorizeAndPerformServiceChargesWithoutApproval':
    'Can Authorize and Perform Service Charges without Approval',

  // Break Types
  'stf.breakTypes.add_break_type': 'Add Break Type',
  'stf.breakTypes.break_type_save_fail': 'Failed to save Break Type',
  'stf.breakTypes.break_type_save_success': '“{{breakName}}” has been saved successfully',
  'stf.breakTypes.compensation': 'Compensation',
  'stf.breakTypes.compensation_type_error': 'Invalid compensation type',
  'stf.breakTypes.compensation_type_paid': 'Paid',
  'stf.breakTypes.compensation_type_unpaid': 'Unpaid',
  'stf.breakTypes.description': 'Description',
  'stf.breakTypes.delete_confirmation_title': 'Are you sure you want to delete \n"{{title}}"',
  'stf.breakTypes.delete_confirmation_button': 'Yes, Delete Break Type',
  'stf.breakTypes.delete_error': 'Failed to delete "{{title}}" from Break Types. Please try again',
  'stf.breakTypes.delete_success': '“{{title}}” has been deleted from Break Types',
  'stf.breakTypes.duration': 'Duration',
  'stf.breakTypes.duration_in_minutes': 'Duration in minutes',
  'stf.breakTypes.duration_info_error':
    'Duration must be between {{min}} minute and {{max}} minutes',
  'stf.breakTypes.edit_break_type': 'Edit Break Type',
  'stf.breakTypes.empty_table': 'There are no Break Types to be displayed',
  'stf.breakTypes.manager_approval': 'Requires manager approval to end break early',
  'stf.breakTypes.manager_approval_description':
    'In some places, laws require staff to take their full break. Turn on this setting to make sure you follow these rules if they apply.',
  'stf.breakTypes.name': 'Break Name',
  'stf.breakTypes.rank_rearrange_fail':
    'An error occurred while attempting to reorganize the Break Types',
  'stf.breakTypes.rank_rearrange_success': 'Break Types have been reorganized successfully',
  'stf.breakTypes.required_validation': '{{value}} is required',
  'stf.breakTypes.text_validation_length': 'Only {{value}} characters allowed',

  // Shifts
  'stf.shifts.shift_management': 'Shift Management',
  'stf.shifts.delete_history': 'Delete History',
  'stf.shifts.force_clock_out': 'Force Clock-out',
  'stf.shifts.add_shift': 'Add Shift',
  'stf.shifts.filter_staff': 'Filter Staff',
  'stf.shifts.all_staff_members': 'All Staff Members',
  'stf.shifts.filter_roles': 'Filter Roles',
  'stf.shifts.all_roles': 'All Roles',
  'stf.shifts.open_closed_shifts': 'Open/Closed Shifts',
  'stf.shifts.all_shifts': 'All Shifts',
  'stf.shifts.open_shifts': 'Open Shifts',
  'stf.shifts.closed_shifts': 'Closed Shifts',
  'stf.shifts.member_name': 'Member Name',
  'stf.shifts.role': 'Role',
  'stf.shifts.shift_start': 'Shift Start',
  'stf.shifts.shift_end': 'Shift End',
  'stf.shifts.duration': 'Duration',
  'stf.shifts.edit_shift': 'Edit Shift',
  'stf.shifts.clock_out': 'Clock Out',
  'stf.shifts.view_report': 'View Report',
  'stf.shifts.view_history': 'View History',
  'stf.shifts.delete_shift': 'Delete Shift',
  'stf.shifts.force_clock_out_all_shifts_confirmation_title':
    'This will clock-out all staff members with an open shift, would you like to proceed?',
  'stf.shifts.force_clock_out_all_shifts_cancel_button': 'No, Return to Shift Management',
  'stf.shifts.force_clock_out_all_shifts_confirmation_button': 'Yes, Clock-out',
  'stf.shifts.force_clock_out_successful':
    'The request to force clock-out staff has been sent to the POS. Refresh this page to update any open shifts.',
  'stf.shifts.refresh': 'Refresh',
  'stf.shifts.force_clock_out_error':
    'Error sending request to force clock out shift(s). Please refresh and try again.',
  'stf.shifts.date_range': 'Date Range',
  'stf.shifts.status_open': 'OPEN',
  'stf.shifts.shifts_empty': 'There are no Shifts to be displayed for the selected range.',
  'stf.shifts.shifts_table_title': 'Staff Shifts',
  'stf.shifts.shifts_table_description': 'Shifts can be edited up to 90 days after being started',
  'stf.shifts.delete_shift_confirmation_title': 'Are you sure you want to delete this shift?',
  'stf.shifts.delete_shift_cancel_button_label': 'Cancel',
  'stf.shifts.delete_shift_confirmation_button_label': 'Confirm',
  'stf.shifts.delete_shift_success': 'Shift successfully deleted',
  'stf.shifts.delete_shift_fail': 'Failed to delete shift',
  'stf.shifts.start_end_time_modal_title_shift_start': 'Shift Start',
  'stf.shifts.start_end_time_modal_title_shift_end': 'Shift End',
  'stf.shifts.start_end_time_modal_subtitle_member_role': '{{memberName}} - {{roleName}}',
  'stf.shifts.start_end_time_modal_save_changes': 'Save Changes',
  'stf.shifts.new.title': 'Add Shift',
  'stf.shifts.edit.title': 'Edit Shift',
  'stf.shifts.new.details': 'Shift Details',
  'stf.shifts.new.member_name': 'Member Name',
  'stf.shifts.new.role': 'Role',
  'stf.shifts.new.rate_of_pay': 'Rate of Pay',
  'stf.shifts.new.shift-start': 'Shift start',
  'stf.shifts.new.shift-end': 'Shift end',
  'stf.shifts.new.date': 'Date',
  'stf.shifts.new.time': 'Time',
  'stf.shifts.new.tips': 'Tips',
  'stf.shifts.new.breaks': 'Breaks',
  'stf.shifts.new.optional': 'Optional',
  'stf.shifts.new.cash_tip': 'Cash Tip',
  'stf.shifts.new.break_name': 'Break name',
  'stf.shifts.new.break_in': 'Break-in time',
  'stf.shifts.new.break_out': 'Break-out time',
  'stf.shifts.shift_save_fail': 'Failed to save Shift',
  'stf.shifts.shift_save_success': 'Shift has been saved successfully',
  'stf.shifts.add_break': 'Add Break',
  'stf.shifts.new.duration': 'Shift duration: {{duration}}',
  'stf.shifts.edit.role_description':
    'Updating the role will apply the latest changes and pay rate',
  'stf.shifts.add_another_break': 'Add Another Break',
  'stf.shifts.required_validation': '{{value}} is required',
  'stf.shifts.error.shift_end_time_validation':
    'Time cannot be earlier than the start time or later than the current time.',
  'stf.shifts.error.shift_start_time_validation': 'Time cannot be later than the shift end time.',
  'stf.shifts.error.shift_duration': 'Shift duration must be greater than 0 minutes',
  'stf.shifts.shift_save_fail_reason': 'Failed to save Shift. {{reason}}',
  'stf.shifts.new.break_duration': 'Break duration: {{duration}}',
  'stf.shifts.new.error.break_start_time_validation':
    'The break start date is not within the shift range.',
  'stf.shifts.new.error.break_end_time_validation':
    'The break end date is not within the shift range.',
  'stf.shifts.error.shift_overlap':
    'Shifts cannot overlap. Please review the start and end times of your shifts.',
  'stf.shifts.error.invalid_shift_start':
    'The shift start time is invalid. Please check the time format or range.',
  'stf.shifts.error.invalid_shift_end':
    'The shift end time is invalid. Please check the time format or range.',
  'stf.shifts.error.shift_break_overlap':
    'Shift breaks cannot overlap. Adjust the break times to avoid conflicts.',
  'stf.shifts.error.shift_open': 'The shift is already open. No further action is needed.',
  'stf.shifts.error.shift_not_open':
    'The shift is not open. Open the shift to proceed with the operation.',
  'stf.shifts.error.break_time_start': 'The break start time must fall within the shift range.',
  'stf.shifts.error.break_time_end': 'The break end time must fall within the shift range.',
  'stf.shifts.error.break_time_duration': 'The break duration must be greater than 0 minutes',
  'stf.shifts.minutes': '{{minutes}} minutes',
  'stf.shifts.hours': '{{hours}} hours',
  'stf.shifts.delete_button_label': 'Delete Shift',
  'stf.shifts.start_time_required': 'Shift start time',
  'stf.shifts.end_time_required': 'Shift end time',
  'stf.shifts.start_date_required': 'Shift start date',
  'stf.shifts.end_date_required': 'Shift end date',
  'stf.shifts.deleted_shifts_history_empty': 'There is no delete history to be displayed.',
  'stf.shifts.staff_member': 'Staff Member',
  'stf.shifts.shift_time': 'Shift Time',
  'stf.shifts.date_deleted': 'Date Deleted',
  'stf.shifts.shift_start_time': 'Shift Start Time',
  'stf.shifts.shift_end_time': 'Shift End Time',
  'stf.shifts.delete_history_table_subtitle': 'Here is a list of shifts that have been deleted',
  'stf.shifts.list_loading_error': 'Error loading shifts. Please refresh and try again.',
  'stf.shifts.shift_history_title': 'Shift History',
  'stf.shifts.shift_history_subtitle': 'Here is a list of changes made to this shift',
  'stf.shifts.previous': 'Previous',
  'stf.shifts.changes': 'Changes',
  'stf.shifts.date_updated': 'Date Updated',
  'stf.shifts.history_empty': 'There is no Shift History for the selected range.',
  'stf.shifts.shift_start_time_changed': 'Shift Start Time Changed',
  'stf.shifts.shift_end_time_changed': 'Shift End Time Changed',
  'stf.shifts.hourly_pay_changed': 'Hourly Pay Changed',
  'stf.shifts.by_name': 'By {{name}}',
  'stf.shifts.shift_added': 'Shift Added',
  'stf.shifts.break_deleted': 'Break Deleted',
  'stf.shifts.break_added': 'Break Added',
  'stf.shifts.break_start_time_changed': 'Break Start Time Changed',
  'stf.shifts.break_end_time_changed': 'Break End Time Changed',
  'stf.shifts.type_populated': '<1>Type:</1> {{type}}',
  'stf.shifts.pay_populated': '<1>Pay:</1> ${{pay}}',
  'stf.shifts.deleted_shifts_history_loading_error':
    'Error loading deleted shifts history. Please refresh and try again.',
  'stf.shifts.shift_start_time_updated': 'Shift Start Time updated',
  'stf.shifts.shift_end_time_updated': 'Shift End Time updated',
  'stf.shifts.shift_update_failed': 'Failed to update Shift.',
  'stf.shifts.shift_update_failed_shift_open': 'Cannot update an open Shift.',
  'stf.shifts.shift_update_failed_shift_overlap':
    'Shift overlaps with another closed shift of that user.',
  'stf.shifts.shift_update_failed_shift_missing_staff':
    'Shift is missing a relation to Staff Member.',
  'stf.shifts.shift_update_failed_shift_starts_after_shift_end':
    'The Shift starts after the end time.',
  'stf.shifts.shift_update_failed_shift_ends_before_shift_start':
    'The Shift ends before the start time.',
  'stf.shifts.shift_update_failed_invalid_shift_length_min_duration':
    'The Shift duration must be greater than 0.',
  'stf.shifts.shift_update_failed_invalid_shift_length':
    'Cannot update a Shift that is older than 90 days.',
  'stf.shifts.shift_update_failed_invalid_shift_start': 'Shift starts after the current time.',
  'stf.shifts.shift_update_failed_invalid_shift_start_service':
    'Shift starts before the venue service time.',
  'stf.shifts.shift_update_failed_invalid_shift_end_service':
    'Shift ends after the venue service time.',
  'stf.shifts.error.break_overlap': 'Break overlaps with another break.',
  'stf.shifts.error.shift_service_time': 'Shift must be within the service time',

  // Payments
  'stf.permissions.payments.section_title': 'Payments',
  'stf.permissions.paymentSettings.manage': 'Can Manage Payment Settings',
  'stf.permissions.paymentTerminals.manage': 'Can Manage Payment Terminals',
  'stf.permissions.paymentCredentials.manage': 'Can Manage Payment Credentials',
}

export default staffEN
