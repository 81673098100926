const crmES = {
  'crm.appbar-title': '[ES] Guest Manager',
  'crm.guestlist-title': '[ES] Guest List',
  'crm.guestlist-table-header-guest-name': '[ES] Guest Name',
  'crm.guestlist-table-header-email-opt-in': '[ES] Email Opt-In',
  'crm.guestlist-table-header-phone': '[ES] Phone',
  'crm.guestlist-table-header-email': '[ES] Email',
  'crm.guestlist-table-header-lifetime-spend': '[ES] Lifetime Spend',
  'crm.guestlist-table-header-last-order-date': '[ES] Last Order Date',
  'crm.guestlist-fetch-error': '[ES] Failed to fetch guest list',
  'crm.guestlist-search': '[ES] Guest Search',
  'crm.guestlist-empty-content':
    '[ES] Loyal Guests are only a few quick steps away. Let us help you get started by clicking the button below.',
  'crm.guestlist-nooption': '[ES] No Guests match your search.',
  'crm.guestlist-moreoptions': '[ES] And {{totalSearch}} more.',
  'crm.guestlist-getstarted': '[ES] Get Started',
  'crm.guestlist-days-since-last-order': '[ES] Days Since Last Order',
  'crm.guestlist-total-spent12m': '[ES] Total Spent (12M)',
  'crm.guestlist-average-spent-12m': '[ES] Average Spent (12M)',
  'crm.guestlist-total-orders': '[ES] Total Orders (12M)',
  'crm.guestlist-birthday': '[ES] Birthday',
  'crm.guestlist-selectalltext': '[ES] Select all {{allGuestCount}} guests',
  'crm.guestlist-selectiontext': '[ES] You have selected {{selectedGuestCount}} guests',
  'crm.guestlist-clearselection': '[ES] Clear Selection',
  'crm.guestlist.email-modal.send-email': '[ES] Send Email',
  'crm.guestlist.email-modal.send-email-subtext': '[ES] This email will be sent to:',
  'crm.guestlist.email-modal.warning-text':
    '[ES] Heads up! We only send emails to guests who have opted-in to receive marketing related emails.',
  'crm.guestlist-dropdowntext': '[ES] Send',
  'crm.guestlist-dropdown-promotion': '[ES] Promotion',
  'crm.guestlist-dropdown-communication': '[ES] Communication',
  'crm.guestlist-dropdown-email': '[ES] Email',
  'crm.guestlist-dropdown-iam': '[ES] In-App Messaging',
  'crm.guestlist.email-modal.dropdown-header': '[ES] Email Template',
  'crm.guestlist.email-modal.dropdown-placeholder': '[ES] Choose a template',
  'crm.guestlist.email-modal.and-more-text': '[ES] And {{remainder}} more',
  'crm.guestlist.email-modal.preview-template': '[ES] Preview Template:',
  'crm.guestlist.email-modal.change-email-text':
    '[ES] Change SendGrid API Key and/or "From" email address:',
  'crm.guestlist.email-modal.back-button': '[ES] Back',
  'crm.guestlist.email-modal.sendgrid-explaination-part-1':
    '[ES] Enter your API key and the email address used to register your SendGrid account to connect.',
  'crm.guestlist.email-modal.sendgrid-explaination-part-2':
    '[ES] Emails sent to guests will be “From” this email address.',
  'crm.guestlist.email-modal.save-settings': '[ES] Save',
  'crm.guestlist.valid-update-api-key': '[ES] Update Successful',
  'crm.guestlist.message-title': '[ES] Title',
  'crm.guestlist.message': '[ES] Message',
  'crm.guestlist.send-grid-success-message':
    '[ES] Your emails have been successfully added to the queue and will be sent shortly',
  'crm.guestlist.send-promotions-success': '[ES] Success! Users have been added to the promotion',
  'crm.guestlist.promotions': '[ES] Promotions',
  'crm.guestlist.send-to-guests': '[ES] Send to {{guestNum}} Guest(s)',
  'crm.guestlist.email': '[ES] Email',
  'crm.guestlist.in-app-messaging': '[ES] In-App Messaging',
  'crm.guestlist.communicate-with-guests': '[ES] Communicate With Guests',
  'crm.guestlist.promotion-to-send': '[ES] Promotion',
  'crm.guestlist.select-a-promotion': '[ES] Select a Promotion',
  'crm.guestlist.no-promotions-available': '[ES] No Promotions available',
  'crm.guestlist.promotion-info': '[ES] This promotion will be sent to:',
  'crm.guestlist.send-a-promotion': '[ES] Send a Promotion',
  'crm.guestlist.send-an-iam': '[ES] Send an In-App Message',
  'crm.guestlist.send-iam-info': '[ES] This In-App message will be sent to:',
  'crm.guestlist.send-iam-title': '[ES] Title',
  'crm.guestlist.send-iam-message': '[ES] Message',
  'crm.guestlist.valid-message-sent': '[ES] Your message was sent successfully',
  'crm.opt-out-marketing-email-success': '[ES] User opted out of marketing email.',
  'crm.opt-out-marketing-email-failure':
    '[ES] There was an error while trying to opt out of marketing email.',
  'crm.opt-out-marketing-email-dialog-title':
    '[ES] Are you sure you want to remove this guest from future email marketing?',
  'crm.opt-out-marketing-email-dialog-message':
    '[ES] This action cannot be undone in TouchBistro Cloud. Only the guest will be able to re-consent to receive email marketing communications in the future.',
  'crm.save-changes': '[ES] Save Changes',
  'crm.cancel': '[ES] Cancel',
  'crm.guestlist.guests-total': '[ES] {{listTotal}} guests out of {{total}} @ {{name}}',
  'crm.guestlist.guests-total-footer': '[ES] {{range}} guests of {{total}}',

  'crm.guest-event-type-enum': {
    guest_created: '[ES] Guest Created',
    item_claimed: '[ES] Item Claimed',
    item_removed: '[ES] Item Removed',
    ordered: '[ES] Ordered',
    points_earned: '[ES] Points Earned',
    points_removed: '[ES] Points Removed',
    promotion_sent: '[ES] Promotion Sent',
    refunded: '[ES] Refunded',
    reward_earned: '[ES] Reward Earned',
    reward_redeemed: '[ES] Reward Redeemed',
    reward_removed: '[ES] Reward Removed',
    reward_returned: '[ES] Reward Returned',
    tag_added: '[ES] Tag Added',
    tag_removed: '[ES] Tag Removed',
  },

  'crm.guest-important-date-enum': {
    ANNIVERSARY: '[ES] Anniversary',
    BIRTHDAY: '[ES] Birthday',
  },

  'crm.guestprofile-back-button': '[ES] Back to Guest List',
  'crm.guest-appbar-title': '[ES] Guest Profile',
  'crm.guest-creation-date': '[ES] Guest since',
  'crm.guest-loyalty-and-reward-section': '[ES] Loyalty & Rewards',
  'crm.guest-activity-section': '[ES] Activity',
  'crm.guest-personal-detail-section': '[ES] Personal Details',
  'crm.guest-email-section': '[ES] Email',
  'crm.guest-phonenumber-section': '[ES] Phone',
  'crm.guest-marketing-section': '[ES] Marketing',
  'crm.guest-recently-ordered-section': '[ES] Most Recently Ordered',
  'crm.guest-email-subscribed': '[ES] Email Subscribed',
  'crm.guest-email-notsubscribed': '[ES] Not Subscribed',
  'crm.guestprofile-fetch-error': '[ES] Failed to fetch guest activities',
  'crm.guest-aggregate-total-spent': '[ES] total spend (12M)',
  'crm.guest-event-activity-feed-empty': '[ES] Your activity feed is currently empty',
}

export default crmES
