const crmEN = {
  'crm.appbar-title': 'Guest Manager',
  'crm.guestlist-title': 'Guest List',
  'crm.guestlist-table-header-guest-name': 'Guest Name',
  'crm.guestlist-table-header-email-opt-in': 'Opt-In Status',
  'crm.guestlist-days-since-last-order': 'Days Since Last Order',
  'crm.guestlist-total-spent12m': 'Total Spent (12M)',
  'crm.guestlist-average-spent-12m': 'Average Spent (12M)',
  'crm.guestlist-total-orders': 'Total Orders (12M)',
  'crm.guestlist-birthday': 'Birthday',
  'crm.guestlist-table-header-phone': 'Phone',
  'crm.guestlist-table-header-email': 'Email',
  'crm.guestlist-table-header-lifetime-spend': 'Lifetime Spend',
  'crm.guestlist-table-header-last-order-date': 'Last Order Date',
  'crm.guestlist-fetch-error': 'Failed to fetch guest list',
  'crm.guestlist-search': 'Guest Search',
  'crm.guestlist-empty-content':
    'Loyal Guests are only a few quick steps away. Let us help you get started by clicking the button below.',
  'crm.guestlist-nooption': 'No Guests match your search.',
  'crm.guestlist-moreoptions': 'And {{totalSearch}} more.',
  'crm.guestlist-getstarted': 'Get Started',
  'crm.guestlist-selectalltext': 'Select all {{allGuestCount}} guests',
  'crm.guestlist-selectiontext': 'You have selected {{selectedGuestCount}} guests',
  'crm.guestlist-clearselection': 'Clear Selection',
  'crm.guestlist-dropdowntext': 'Send',
  'crm.guestlist-dropdown-promotion': 'Promotion',
  'crm.guestlist-dropdown-communication': 'Communication',
  'crm.guestlist-dropdown-email': 'Email',
  'crm.guestlist-dropdown-iam': 'In-App Messaging',
  'crm.guestlist.email-modal.send-email': 'Send Email',
  'crm.guestlist.email-modal.send-email-subtext': 'This email will be sent to:',
  'crm.guestlist.email-modal.dropdown-header': 'Email Template',
  'crm.guestlist.email-modal.dropdown-placeholder': 'Choose a template',
  'crm.guestlist.email-modal.warning-text':
    'Heads up! We only send emails to guests who have opted-in to receive marketing related emails.',
  'crm.guestlist.email-modal.and-more-text': 'And {{remainder}} more',
  'crm.guestlist.email-modal.preview-template': 'Preview Template:',
  'crm.guestlist.email-modal.change-email-text':
    'Change SendGrid API Key and/or "From" email address:',
  'crm.guestlist.email-modal.back-button': 'Back',
  'crm.guestlist.email-modal.sendgrid-explaination-part-1':
    'Enter your API key and the email address used to register your SendGrid account to connect.',
  'crm.guestlist.email-modal.sendgrid-explaination-part-2':
    'Emails sent to guests will be “From” this email address.',
  'crm.guestlist.email-modal.save-settings': 'Save',
  'crm.guestlist.valid-update-api-key': 'Update Successful',
  'crm.guestlist.message-title': 'Title',
  'crm.guestlist.message': 'Message',
  'crm.guestlist.send-grid-success-message':
    'Your emails have been successfully added to the queue and will be sent shortly',
  'crm.guestlist.send-promotions-success': 'Success! Users have been added to the promotion',
  'crm.guestlist.promotions': 'Promotions',
  'crm.guestlist.send-to-guests': 'Send to {{guestNum}} Guest(s)',
  'crm.guestlist.email': 'Email',
  'crm.guestlist.in-app-messaging': 'In-App Messaging',
  'crm.guestlist.communicate-with-guests': 'Communicate With Guests',
  'crm.guestlist.promotion-to-send': 'Promotion',
  'crm.guestlist.select-a-promotion': 'Select a Promotion',
  'crm.guestlist.no-promotions-available': 'No Promotions available',
  'crm.guestlist.promotion-info': 'This promotion will be sent to:',
  'crm.guestlist.send-a-promotion': 'Send a Promotion',
  'crm.guestlist.send-an-iam': 'Send an In-App Message',
  'crm.guestlist.send-iam-info': 'This In-App message will be sent to:',
  'crm.guestlist.send-iam-title': 'Title',
  'crm.guestlist.send-iam-message': 'Message',
  'crm.guestlist.valid-message-sent': 'Your message was sent successfully',
  'crm.guestlist.guests-total': '{{listTotal}} guests out of {{total}} @ {{name}}',
  'crm.guestlist.guests-total-footer': '{{range}} guests of {{total}}',

  'crm.guest-event-type-enum': {
    guest_created: 'Guest Created',
    item_claimed: 'Item Claimed',
    item_removed: 'Item Removed',
    ordered: 'Ordered',
    points_earned: 'Points Earned',
    points_removed: 'Points Removed',
    promotion_sent: 'Promotion Sent',
    refunded: 'Refunded',
    reward_earned: 'Reward Earned',
    reward_redeemed: 'Reward Redeemed',
    reward_removed: 'Reward Removed',
    reward_returned: 'Reward Returned',
    tag_added: 'Tag Added',
    tag_removed: 'Tag Removed',
  },

  'crm.guest-important-date-enum': {
    ANNIVERSARY: 'Anniversary',
    BIRTHDAY: 'Birthday',
  },

  'crm.guestprofile-back-button': 'Back to Guest List',
  'crm.guest-appbar-title': 'Guest Profile',
  'crm.guest-creation-date': 'Guest since',
  'crm.guest-loyalty-and-reward-section': 'Loyalty & Rewards',
  'crm.guest-activity-section': 'Activity',
  'crm.guest-personal-detail-section': 'Personal Details',
  'crm.guest-email-section': 'Email',
  'crm.guest-phonenumber-section': 'Phone',
  'crm.guest-marketing-section': 'Marketing',
  'crm.guest-recently-ordered-section': 'Most Recently Ordered',
  'crm.guest-email-subscribed': 'Email Subscribed',
  'crm.guest-email-notsubscribed': 'Not Subscribed',
  'crm.guestprofile-fetch-error': 'Failed to fetch guest activities',
  'crm.guest-aggregate-total-spent': 'total spend (12M)',
  'crm.guest-event-activity-feed-empty': 'Your activity feed is currently empty',
  'crm.opt-out-marketing-email-success': 'User opted out of marketing email.',
  'crm.opt-out-marketing-email-failure':
    'There was an error while trying to opt out of marketing email.',
  'crm.opt-out-marketing-email-dialog-title':
    'Are you sure you want to remove this guest from future email marketing?',
  'crm.opt-out-marketing-email-dialog-message':
    'This action cannot be undone in TouchBistro Cloud. Only the guest will be able to re-consent to receive email marketing communications in the future.',
  'crm.save-changes': 'Save Changes',
  'crm.cancel': 'Cancel',
}

export default crmEN
