// Can't use node-common due to the error "Module not found: Can't resolve 'child_process'"
// "child_process" is only available  on node environment, not on browsers

/**
 * PLACE ONLY VARIABLES THAT ARE SAFE TO BE USED ON FRONTEND AND BACKEND.
 * VARIABLES WITHOUT 'NEXT_PUBLIC_' prefix are only allowed to be accessed from the server code in next.js
 */
const environment = process.env.NEXT_PUBLIC_NODE_ENV ?? ''
const urlBase = process.env.NEXT_PUBLIC_URL_BASE ?? ''
const vcsArkProxyUrlBase = `/api`
const venueAdminUrlBase = process.env.NEXT_PUBLIC_VENUE_ADMIN_URL_BASE ?? ''
const venueAgentUrlBase = process.env.NEXT_PUBLIC_VENUE_AGENT_URL_BASE ?? ''
const ldClientId = process.env.NEXT_PUBLIC_LD_CLIENT_ID ?? ''
const ddRumApplicationId = process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID ?? ''
const ddRumClientToken = process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN ?? ''
const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''
const coverdashStagingLicenseKey = process.env.NEXT_PUBLIC_COVERDASH_STAGING_LICENSE_KEY ?? ''
const coverdashProductionLicenseKey = process.env.NEXT_PUBLIC_COVERDASH_PRODUCTION_LICENSE_KEY ?? ''
const orderingBaseUrl = process.env.NEXT_PUBLIC_ORDERING_BASE_URL ?? ''
const reservationsBaseUrl = process.env.NEXT_PUBLIC_RESERVATIONS_BASE_URL ?? ''
const reservationsBaseApiUrl = process.env.NEXT_PUBLIC_RESERVATIONS_BASE_API_URL ?? ''
const arkUrlBase = process.env.NEXT_PUBLIC_ARK_URL_BASE ?? ''
const snackBarAutoHideDuration = parseInt(
  process.env.NEXT_PUBLIC_SNACKBAR_AUTO_HIDE_DURATION ?? '5000',
  10
)
const pusherAppKey = process.env.NEXT_PUBLIC_PUSHER_APP_KEY ?? ''
const pusherCluster = process.env.NEXT_PUBLIC_PUSHER_CLUSTER ?? 'mt1'
const pusherLocalEnvKey = process.env.NEXT_PUBLIC_PUSHER_LOCALENV_KEY ?? ''
const loyaltyCWABaseUrl = process.env.NEXT_PUBLIC_LOYALTY_CWA_BASE_URL ?? ''
const salesforceSSOInitializationUrl = process.env.NEXT_PUBLIC_SALESFORCE_XP_CLOUD_INIT_URL ?? ''

const pendoId = process.env.NEXT_PUBLIC_PENDO_ID ?? ''
const buildAssetsPath = (path: string): string => {
  /* istanbul ignore next */
  return `${process.env.NEXT_PUBLIC_ASSET_PREFIX ?? ''}${path}`
}
const browserConfig = {
  // URLs
  arkUrlBase,
  orderingBaseUrl,
  reservationsBaseUrl,
  reservationsBaseApiUrl,
  urlBase,
  vcsArkProxyUrlBase,
  venueAdminUrlBase,
  venueAgentUrlBase,
  loyaltyCWABaseUrl,
  salesforceSSOInitializationUrl,
  // other paths
  buildAssetsPath,
  ddRumApplicationId,
  ddRumClientToken,
  environment,
  googleMapsApiKey,
  coverdashStagingLicenseKey,
  coverdashProductionLicenseKey,
  ldClientId,
  snackBarAutoHideDuration,
  pendoId,
  pusherAppKey,
  pusherCluster,
  pusherLocalEnvKey,
}

export default browserConfig
