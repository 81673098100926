const staffES = {
  'stf.loyalty_groups': 'Grupos de Guest Engagement',
  'stf.select_groups': 'Seleccionar grupos',
  'stf.role_information': '[ES] Role Information',
  'stf.permissions.main_title': '[ES] Permissions',
  'stf.permissions.role_name_text': '[ES] Role Name',
  'stf.permissions.role_description_text': '[ES] Role Description',

  // POS
  'stf.permissions.pos': '[ES] POS',
  'stf.permissions.pos.restart': '[ES] Can Start Day (Restarting the Android app)',
  'stf.permissions.pos.launchTouchBistro': '[ES] Can Action Update (within TouchBistro)',
  'stf.permissions.pos.clockInOnly': '[ES] Clock-In / Clock Out Only',
  'stf.permissions.order.void': '[ES] Can Authorize and Perform Voids without Approval',
  'stf.permissions.order.discount': '[ES] Can Authorize and Perform Discounts without Approval',
  'stf.permissions.order.gratuity': '[ES] Can Authorize and Add Gratuity',
  'stf.permissions.cashdrawer.open': '[ES] Can Open Cash Drawer',
  'stf.permissions.order.refund': '[ES] Can Refund Closed Bills',
  'stf.permissions.cashdrawer.payInOut': '[ES] Can Perform Pay-Ins/Pay-Outs',
  'stf.permissions.pos.accessTableIfSameRole':
    '[ES] Can Access Other Staff Tables without Approval',
  'stf.permissions.pos.accessTakeout': '[ES] Can Access Takeout / Delivery',
  'stf.permissions.pos.enableFastBar': '[ES] Enable Fast Bar On Point of Sale',
  'stf.permissions.pos.requiresCashTip': '[ES] Required Recording of Cash Tips',
  'stf.permissions.pos.accessCashRegister': '[ES] Can Access Cash Registers on Floor Plan',
  'stf.permissions.pos.accessCustomerAccounts': '[ES] Can Access Accounts',
  'stf.permissions.pos.enablePersonalRegister': '[ES] Can Access Personal Register',
  'stf.permissions.pos.managePaymentGateways': '[ES] Can Manage Payment Gateways',
  'stf.permissions.pos.manageOrders': '[ES] Can Manage Orders',
  'stf.permissions.pos.performEndOfDay': '[ES] Can Perform EOD',
  'stf.permissions.pos.admin': '[ES] POS Administrative Access',
  'stf.permissions.report.endOfShift': '[ES] Show Current and Past Shifts',
  'stf.permissions.autoProcessOrder.read': '[ES] Can View an Auto Processed Order',
  'stf.permissions.autoProcessOrder.update': '[ES] Can Update an Auto Processed Order',
  'stf.permissions.autoProcessOrder.delete': '[ES] Can Delete an Auto Processed Order',
  'stf.permissions.order.take': '[ES] Can Take Orders (Android only)',
  'stf.permissions.partners.overrideShift':
    'Permitir anulaciones de turnos con integraciones de socios',

  // Cash Management
  'stf.permissions.report.manageDepositsBlind': '[ES] Can Create / Edit Blind Cash Deposits',
  'stf.permissions.report.manageDepositsFull': '[ES] Can Create / Edit Full Cash Deposits',

  // Staff
  'stf.permissions.staff.section_title': '[ES] Staff',
  'stf.permissions.staff': '[ES] Can Manage Staff',
  'stf.permissions.staff.create': '[ES] Add a User',
  'stf.permissions.staff.update': '[ES] Edit a User',
  'stf.permissions.staff.delete': '[ES] Delete a User',
  'stf.permissions.staff.read': '[ES] View Roles',

  'stf.permissions.roles': '[ES] Can Manage Roles',
  'stf.permissions.roles.create': '[ES] Add a Role',
  'stf.permissions.roles.update': '[ES] Edit a Role',
  'stf.permissions.roles.delete': '[ES] Delete a Role',

  'stf.permissions.shifts.manage': '[ES] Can Manage Shifts',

  'stf.permissions.overtimeRules.manage': '[ES] Can Manage Overtime Rules',

  // Devices
  'stf.permissions.device.section_title': '[ES] Devices',

  'stf.permissions.device': '[ES] Can Manage Devices',
  'stf.permissions.device.read': '[ES] Access a Device',
  'stf.permissions.device.update': '[ES] Edit a Device',

  'stf.permissions.printer': '[ES] Can Manage Printers',
  'stf.permissions.printer.read': '[ES] Access a Printer',
  'stf.permissions.printer.create': '[ES] Add a Printer',
  'stf.permissions.printer.update': '[ES] Edit a Printer',
  'stf.permissions.printer.delete': '[ES] Remove a Printer',

  'stf.permissions.pos.manage': '[ES] POS Manage - Other Devices',

  // Menu
  'stf.permissions.menu.section_title': '[ES] Menu',

  'stf.permissions.operationalMenu.publish': '[ES] Menu Publishing',
  'stf.permissions.operationalMenu.readHistory': '[ES] Menu Read History',

  'stf.permissions.menu': '[ES] Can Manage Menus',
  'stf.permissions.menu.read': '[ES] View a Menu',
  'stf.permissions.menu.create': '[ES] Create a Menu',
  'stf.permissions.menu.update': '[ES] Update a Menu',
  'stf.permissions.menu.delete': '[ES] Delete a Menu',

  'stf.permissions.menuEntityHistory.read': '[ES] Menu Read Entity History',

  'stf.permissions.menuGroup': '[ES] Can Manage Menu Groups',
  'stf.permissions.menuGroup.read': '[ES] View a Menu Group',
  'stf.permissions.menuGroup.create': '[ES] Create a Menu Group',
  'stf.permissions.menuGroup.update': '[ES] Update a Menu Group',
  'stf.permissions.menuGroup.delete': '[ES] Delete a Menu Group',

  'stf.permissions.menuItem': '[ES] Can Manage Menu Items',
  'stf.permissions.menuItem.read': '[ES] View a Menu Item',
  'stf.permissions.menuItem.create': '[ES] Create a Menu Item',
  'stf.permissions.menuItem.update': '[ES] Update a Menu Item',
  'stf.permissions.menuItem.delete': '[ES] Delete a Menu Item',

  'stf.permissions.modifierGroup': '[ES] Can Manage Modifier Groups',
  'stf.permissions.modifierGroup.read': '[ES] View a Modifier Group',
  'stf.permissions.modifierGroup.create': '[ES] Create a Modifier Group',
  'stf.permissions.modifierGroup.update': '[ES] Update a Modifier Group',
  'stf.permissions.modifierGroup.delete': '[ES] Delete a Modifier Group',

  'stf.permissions.modifier': '[ES] Can Manage Modifiers',
  'stf.permissions.modifier.read': '[ES] View a Modifiers',
  'stf.permissions.modifier.create': '[ES] Create a Modifiers',
  'stf.permissions.modifier.update': '[ES] Update a Modifiers',
  'stf.permissions.modifier.delete': '[ES] Delete a Modifiers',

  'stf.permissions.salesCategory': '[ES] Can Manage Sales Categories',
  'stf.permissions.salesCategory.read': '[ES] View a Sales Category',
  'stf.permissions.salesCategory.create': '[ES] Create a Sales Category',
  'stf.permissions.salesCategory.update': '[ES] Update a Sales Category',
  'stf.permissions.salesCategory.delete': '[ES] Delete a Sales Category',

  'stf.permissions.bulkMenu': '[ES] Can Manage Bulk Menu Editing',
  'stf.permissions.bulkMenu.create': '[ES] Create a Bulk Menu',
  'stf.permissions.bulkMenu.delete': '[ES] Delete a Bulk Menu',

  'stf.permissions.image': '[ES] Can Manage Menu Images',
  'stf.permissions.image.create': '[ES] Create a menu image',
  'stf.permissions.image.read': '[ES] View a menu image',
  'stf.permissions.image.delete': '[ES] Delete a menu image',

  'stf.permissions.scheduledPublication': '[ES] Can Manage Scheduling Publications',
  'stf.permissions.scheduledPublication.update': '[ES] Update a Scheduling Publication',
  'stf.permissions.scheduledPublication.read': '[ES] View a Scheduling Publication',
  'stf.permissions.scheduledPublication.delete': '[ES] Delete a Scheduling Publication',

  'stf.permissions.promotion': '[ES] Can Manage Promotional Pricing',
  'stf.permissions.promotion.create': '[ES] Create a Promotional Pricing',
  'stf.permissions.promotion.update': '[ES] Update a Promotional Pricing',
  'stf.permissions.promotion.read': '[ES] View a Promotional Pricing',
  'stf.permissions.promotion.delete': '[ES] Delete a Promotional Pricing',

  'stf.permissions.voidReason': '[ES] Can Manage Voids',
  'stf.permissions.voidReason.read': '[ES] Access a Void',
  'stf.permissions.voidReason.create': '[ES] Create a Void',
  'stf.permissions.voidReason.update': '[ES] Update a Void',
  'stf.permissions.voidReason.delete': '[ES] Remove a Void',

  'stf.permissions.discount': '[ES] Can Manage Discounts',
  'stf.permissions.discount.read': '[ES] Access a Discount',
  'stf.permissions.discount.create': '[ES] Create a Discount',
  'stf.permissions.discount.update': '[ES] Update a Discount',
  'stf.permissions.discount.delete': '[ES] Remove a Discount',

  'stf.permissions.stockAvailability': '[ES] Can Manage Stock Availability',

  'stf.permissions.menuEntryHistory.read': '[ES] Can View Menu Entry History',

  // Online Ordering

  // Reports
  'stf.permissions.reports.section_title': '[ES] Reports',
  'stf.permissions.reports.cash_deposit': '[ES] Cash Deposit',
  'stf.permissions.reports': 'Can Access Reports',
  'stf.permissions.report.read': '[ES] Can Access Reports',
  'stf.permissions.eodreport.read': '[ES] Can Access End of Day Report',

  // Floor Plan
  'stf.permissions.floorplan.section_title': '[ES] Floor Plan',

  'stf.permissions.floorplan': '[ES] Can Manage Floor Plan',
  'stf.permissions.floorplan.read': '[ES] Read Floor Plan',
  'stf.permissions.floorplan.create': '[ES] Create a Floor Plan',
  'stf.permissions.floorplan.update': '[ES] Update a Floor Plan',
  'stf.permissions.floorplan.delete': '[ES] Delete a Floor Plan',

  // Guest Engagement
  'stf.permissions.guest_engagement.section_title': '[ES] Guest Engagement',

  'stf.permissions.guestEngagement': '[ES] Can Manage Rewards',
  'stf.permissions.guestEngagement.read': '[ES] Access a Reward',
  'stf.permissions.guestEngagement.create': '[ES] Create a Reward',
  'stf.permissions.guestEngagement.update': '[ES] Update a Reward',
  'stf.permissions.guestEngagement.delete': '[ES] Delete a Reward',

  // Reservations
  'stf.permissions.reservations.section_title': '[ES] Reservations',
  'stf.permissions.reservations.allSettings': '[ES] Can Manage All Reservation Settings',
  'stf.permissions.reservations.manageFOH': '[ES] Can Manage FOH Reservation Activity',

  'stf.permissions.reservationSetting': '[ES] Can Manage Reservations',
  'stf.permissions.reservationSetting.create': '[ES] Create a Reservation',
  'stf.permissions.reservationSetting.read': '[ES] Access a Reservation',
  'stf.permissions.reservationSetting.update': '[ES] Update a Reservation',
  'stf.permissions.reservationSetting.delete': '[ES] Remove a Reservation',

  // Settings
  'stf.permissions.settings.section_title': '[ES] Manage Settings & Venue Info',

  'stf.permissions.venue.update': '[ES] Can Manage Venue Details',
  'stf.permissions.businessday.manage': '[ES] Can manage Business Day',

  'stf.permissions.deliveryBounds': '[ES] Can Manage Online Ordering Settings',
  'stf.permissions.deliveryBounds.create': '[ES] Create a delivery boundary',
  'stf.permissions.deliveryBounds.read': '[ES] Read a delivery boundary',
  'stf.permissions.deliveryBounds.update': '[ES] Update a delivery boundary',
  'stf.permissions.deliveryBounds.delete': '[ES] Delete a delivery boundary',
  'stf.permissions.serviceCharge': '[ES] Can Manage Service Charges',
  'stf.permissions.serviceCharge.create': '[ES] Create a service charge',
  'stf.permissions.serviceCharge.read': '[ES] Read a service charge',
  'stf.permissions.serviceCharge.update': '[ES] Update a service charge',
  'stf.permissions.serviceCharge.delete': '[ES] Delete a service charge',

  'stf.permissions.tax': '[ES] Can Manage Taxes',
  'stf.permissions.tax.read': '[ES] Access a Tax',
  'stf.permissions.tax.create': '[ES] Create a Tax',
  'stf.permissions.tax.update': '[ES] Update a Tax',
  'stf.permissions.tax.delete': '[ES] Delete a Tax',

  'stf.permissions.branding': '[ES] Can Manage Branding',
  'stf.permissions.branding.create': '[ES] Create a Brand',
  'stf.permissions.branding.read': '[ES] Access a Brand',
  'stf.permissions.branding.update': '[ES] Update a Brand',
  'stf.permissions.branding.delete': '[ES] Remove a Brand',

  'stf.permissions.hoursAndScheduling.update': 'Puede administrar horas y programación',
  'stf.permissions.hoursAndScheduling.read': '[ES] Can Read Hours & Scheduling',
  'stf.permissions.venueInfoDayParts.update': '[ES] Can Manage Day Parts',
  'stf.permissions.venueInfoDayParts.read': '[ES] Can Read Day Parts',
  'stf.permissions.venueInfoOperatingHours.update': '[ES] Can Manage Operating Hours',
  'stf.permissions.venueInfoOperatingHours.read': '[ES] Can Read Operating Hours',
  'stf.permissions.venueInfoServiceTime.update': '[ES] Can Manage Service Time',
  'stf.permissions.venueInfoServiceTime.read': '[ES] Can Read Service Time',

  // Payments Portal
  'stf.permissions.payments_portal.section_title': '[ES] Payments Portal',

  'stf.permissions.payments': '[ES] Access to CS Portal (TB Payments)',
  'stf.permissions.payments.create': '[ES] Create Payments',
  'stf.permissions.payments.delete': '[ES] Remove Payments',
  'stf.permissions.payments.read': '[ES] Access Payments',
  'stf.permissions.payments.update': '[ES] Update Payments',

  // App Marketplace
  'stf.permissions.marketplace.section_title': '[ES] App Marketplace',
  'stf.permissions.marketplace.manage': '[ES] Can Manage App Marketplace',

  // Service Charges
  'stf.permissions.pos.authorizeAndPerformServiceChargesWithoutApproval':
    '[ES] Can Authorize and Perform Service Charges without Approval',

  // Break Types
  'stf.breakTypes.add_break_type': '[ES] Add Break Type',
  'stf.breakTypes.break_type_save_fail': '[ES] Failed to save Break Type',
  'stf.breakTypes.break_type_save_success': '[ES] “{{breakName}}” has been saved successfully',
  'stf.breakTypes.compensation': '[ES] Compensation',
  'stf.breakTypes.compensation_type_error': '[ES] Invalid compensation type',
  'stf.breakTypes.compensation_type_paid': '[ES] Paid',
  'stf.breakTypes.compensation_type_unpaid': '[ES] Unpaid',
  'stf.breakTypes.description': '[ES] Description',
  'stf.breakTypes.delete_confirmation_title': '[ES] Are you sure you want to delete \n"{{title}}"',
  'stf.breakTypes.delete_confirmation_button': '[ES] Yes, Delete Break Type',
  'stf.breakTypes.delete_error':
    '[ES] Failed to delete "{{title}}" from Break Types. Please try again',
  'stf.breakTypes.delete_success': '[ES] “{{title}}” has been deleted from Break Types',
  'stf.breakTypes.duration': '[ES] Duration',
  'stf.breakTypes.duration_in_minutes': '[ES] Duration in minutes',
  'stf.breakTypes.duration_info_error':
    '[ES] Duration must be between {{min}} minute and {{max}} minutes',
  'stf.breakTypes.edit_break_type': '[ES] Edit Break Type',
  'stf.breakTypes.empty_table': '[ES] There are no Break Types to be displayed',
  'stf.breakTypes.manager_approval': '[ES] Requires manager approval to end break early',
  'stf.breakTypes.manager_approval_description':
    '[ES] In some places, laws require staff to take their full break. Turn on this setting to make sure you follow these rules if they apply.',
  'stf.breakTypes.name': '[ES] Break Name',
  'stf.breakTypes.rank_rearrange_fail':
    '[ES] An error occurred while attempting to reorganize the Break Types',
  'stf.breakTypes.rank_rearrange_success': '[ES] Break Types have been reorganized successfully',
  'stf.breakTypes.required_validation': '[ES] {{value}} is required',
  'stf.breakTypes.text_validation_length': '[ES] Only {{value}} characters allowed',

  // Shifts
  'stf.shifts.shift_management': '[ES] Shift Management',
  'stf.shifts.delete_history': '[ES] Delete History',
  'stf.shifts.force_clock_out': '[ES] Force Clock-out',
  'stf.shifts.add_shift': '[ES] Add Shift',
  'stf.shifts.filter_staff': '[ES] Filter Staff',
  'stf.shifts.all_staff_members': '[ES] All Staff Members',
  'stf.shifts.filter_roles': '[ES] Filter Roles',
  'stf.shifts.all_roles': '[ES] All Roles',
  'stf.shifts.open_closed_shifts': '[ES] Open/Closed Shifts',
  'stf.shifts.all_shifts': '[ES] All Shifts',
  'stf.shifts.open_shifts': '[ES] Open Shifts',
  'stf.shifts.closed_shifts': '[ES] Closed Shifts',
  'stf.shifts.member_name': '[ES] Member Name',
  'stf.shifts.role': '[ES] Role',
  'stf.shifts.shift_start': '[ES] Shift Start',
  'stf.shifts.shift_end': '[ES] Shift End',
  'stf.shifts.duration': '[ES] Duration',
  'stf.shifts.edit_shift': '[ES] Edit Shift',
  'stf.shifts.clock_out': '[ES] Clock Out',
  'stf.shifts.view_report': '[ES] View Report',
  'stf.shifts.view_history': '[ES] View History',
  'stf.shifts.delete_shift': '[ES] Delete Shift',
  'stf.shifts.force_clock_out_all_shifts_confirmation_title':
    '[ES] This will clock-out all staff members with an open shift, would you like to proceed?',
  'stf.shifts.force_clock_out_all_shifts_cancel_button': '[ES] No, Return to Shift Management',
  'stf.shifts.force_clock_out_all_shifts_confirmation_button': '[ES] Yes, Clock-out',
  'stf.shifts.force_clock_out_successful':
    '[ES] The request to force clock-out staff has been sent to the POS. Refresh this page to update any open shifts.',
  'stf.shifts.refresh': '[ES] Refresh',
  'stf.shifts.force_clock_out_error':
    '[ES] Error sending request to force clock out shift(s). Please refresh and try again.',
  'stf.shifts.date_range': '[ES] Date Range',
  'stf.shifts.status_open': '[ES] OPEN',
  'stf.shifts.shifts_empty': '[ES] There are no Shifts to be displayed for the selected range.',
  'stf.shifts.shifts_table_title': '[ES] Staff Shifts',
  'stf.shifts.shifts_table_description':
    '[ES] Shifts can be edited up to 90 days after being started',
  'stf.shifts.delete_shift_confirmation_title': '[ES] Are you sure you want to delete this shift?',
  'stf.shifts.delete_shift_cancel_button_label': '[ES] Cancel',
  'stf.shifts.delete_shift_confirmation_button_label': '[ES] Confirm',
  'stf.shifts.delete_shift_success': '[ES] Shift successfully deleted',
  'stf.shifts.delete_shift_fail': '[ES] Failed to delete shift',
  'stf.shifts.start_end_time_modal_title_shift_start': '[ES] Shift Start',
  'stf.shifts.start_end_time_modal_title_shift_end': '[ES] Shift End',
  'stf.shifts.start_end_time_modal_subtitle_member_role': '[ES] {{memberName}} - {{roleName}}',
  'stf.shifts.start_end_time_modal_save_changes': '[ES] Save Changes',
  'stf.shifts.new.title': '[ES] Add Shift',
  'stf.shifts.edit.title': '[ES] Edit Shift',
  'stf.shifts.new.details': '[ES] Shift Details',
  'stf.shifts.new.member_name': '[ES] Member Name',
  'stf.shifts.new.role': '[ES] Role',
  'stf.shifts.new.rate_of_pay': '[ES] Rate of Pay',
  'stf.shifts.new.shift-start': '[ES] Shift start',
  'stf.shifts.new.shift-end': '[ES] Shift end',
  'stf.shifts.new.date': '[ES] Date',
  'stf.shifts.new.time': '[ES] Time',
  'stf.shifts.new.tips': '[ES] Tips',
  'stf.shifts.new.breaks': '[ES] Breaks',
  'stf.shifts.new.optional': '[ES] Optional',
  'stf.shifts.new.cash_tip': '[ES] Cash Tip',
  'stf.shifts.new.break_name': '[ES] Break name',
  'stf.shifts.new.break_in': '[ES] Break-in time',
  'stf.shifts.new.break_out': '[ES] Break-out time',
  'stf.shifts.shift_save_fail': '[ES] Failed to save Shift',
  'stf.shifts.shift_save_success': '[ES] Shift has been saved successfully',
  'stf.shifts.add_break': '[ES] Add Break',
  'stf.shifts.new.duration': '[ES] Shift duration: {{duration}}',
  'stf.shifts.edit.role_description':
    '[ES] Updating the role will apply the latest changes and pay rate',
  'stf.shifts.add_another_break': '[ES] Add Another Break',
  'stf.shifts.required_validation': '[ES] {{value}} is required',
  'stf.shifts.error.shift_end_time_validation':
    '[ES] Time cannot be earlier than the start time or later than the current time.',
  'stf.shifts.error.shift_start_time_validation':
    '[ES] Time cannot be later than the shift end time.',
  'stf.shifts.error.shift_duration': '[ES] Shift duration must be greater than 0 minutes',
  'stf.shifts.shift_save_fail_reason': '[ES] Failed to save Shift. {{reason}}',
  'stf.shifts.new.break_duration': '[ES] Break duration: {{duration}}',
  'stf.shifts.new.error.break_start_time_validation':
    '[ES] The break start date is not within the shift range.',
  'stf.shifts.new.error.break_end_time_validation':
    '[ES] The break end date is not within the shift range.',
  'stf.shifts.error.shift_overlap':
    '[ES] Shifts cannot overlap. Please review the start and end times of your shifts.',
  'stf.shifts.error.invalid_shift_start':
    '[ES] The shift start time is invalid. Please check the time format or range.',
  'stf.shifts.error.invalid_shift_end':
    '[ES] The shift end time is invalid. Please check the time format or range.',
  'stf.shifts.error.shift_break_overlap':
    '[ES] Shift breaks cannot overlap. Adjust the break times to avoid conflicts.',
  'stf.shifts.error.shift_open': '[ES] The shift is already open. No further action is needed.',
  'stf.shifts.error.shift_not_open':
    '[ES] The shift is not open. Open the shift to proceed with the operation.',
  'stf.shifts.error.break_time_start':
    '[ES] The break start time must fall within the shift range.',
  'stf.shifts.error.break_time_end': '[ES] The break end time must fall within the shift range.',
  'stf.shifts.error.break_time_duration': '[ES] The break duration must be greater than 0 minutes',
  'stf.shifts.minutes': '[ES] {{minutes}} minutes',
  'stf.shifts.hours': '[ES] {{hours}} hours',
  'stf.shifts.delete_button_label': '[ES] Delete Shift',
  'stf.shifts.start_time_required': '[ES] Shift start time',
  'stf.shifts.end_time_required': '[ES] Shift end time',
  'stf.shifts.start_date_required': '[ES] Shift start date',
  'stf.shifts.end_date_required': '[ES] Shift end date',
  'stf.shifts.deleted_shifts_history_empty': '[ES] There is no delete history to be displayed.',
  'stf.shifts.staff_member': '[ES] Staff Member',
  'stf.shifts.shift_time': '[ES] Shift Time',
  'stf.shifts.date_deleted': '[ES] Date Deleted',
  'stf.shifts.shift_start_time': '[ES] Shift Start Time',
  'stf.shifts.shift_end_time': '[ES] Shift End Time',
  'stf.shifts.delete_history_table_subtitle':
    '[ES] Here is a list of shifts that have been deleted',
  'stf.shifts.list_loading_error': '[ES] Error loading shifts. Please refresh and try again.',
  'stf.shifts.shift_history_title': '[ES] Shift History',
  'stf.shifts.shift_history_subtitle': '[ES] Here is a list of changes made to this shift',
  'stf.shifts.previous': '[ES] Previous',
  'stf.shifts.changes': '[ES] Changes',
  'stf.shifts.date_updated': '[ES] Date Updated',
  'stf.shifts.history_empty': '[ES] There is no Shift History for the selected range.',
  'stf.shifts.shift_start_time_changed': '[ES] Shift Start Time Changed',
  'stf.shifts.shift_end_time_changed': '[ES] Shift End Time Changed',
  'stf.shifts.hourly_pay_changed': '[ES] Hourly Pay Changed',
  'stf.shifts.by_name': '[ES] By {{name}}',
  'stf.shifts.shift_added': '[ES] Shift Added',
  'stf.shifts.break_deleted': '[ES] Break Deleted',
  'stf.shifts.break_added': '[ES] Break Added',
  'stf.shifts.break_start_time_changed': '[ES] Break Start Time Changed',
  'stf.shifts.break_end_time_changed': '[ES] Break End Time Changed',
  'stf.shifts.type_populated': '[ES] <1>Type:</1> {{type}}',
  'stf.shifts.pay_populated': '[ES] <1>Pay:</1> {{pay}}',
  'stf.shifts.deleted_shifts_history_loading_error':
    '[ES] Error loading deleted shifts history. Please refresh and try again.',
  'stf.shifts.shift_start_time_updated': '[ES] Shift Start Time updated',
  'stf.shifts.shift_end_time_updated': '[ES] Shift End Time updated',
  'stf.shifts.shift_update_failed': '[ES] Failed to update Shift.',
  'stf.shifts.shift_update_failed_shift_open': '[ES] Cannot update an open Shift.',
  'stf.shifts.shift_update_failed_shift_overlap':
    '[ES] Shift overlaps with another closed shift of that user.',
  'stf.shifts.shift_update_failed_shift_missing_staff':
    '[ES] Shift is missing a relation to Staff Member.',
  'stf.shifts.shift_update_failed_shift_starts_after_shift_end':
    '[ES] he Shift starts after the end time.',
  'stf.shifts.shift_update_failed_shift_ends_before_shift_start':
    '[ES] The Shift ends before the start time.',
  'stf.shifts.shift_update_failed_invalid_shift_length_min_duration':
    '[ES] The Shift duration must be greater than 0.',
  'stf.shifts.shift_update_failed_invalid_shift_length':
    '[ES] Cannot update a Shift that is older than 90 days.',
  'stf.shifts.shift_update_failed_invalid_shift_start': '[ES] Shift starts after the current time.',
  'stf.shifts.shift_update_failed_invalid_shift_start_service':
    '[ES] Shift starts before the venue service time.',
  'stf.shifts.shift_update_failed_invalid_shift_end_service':
    '[ES] Shift ends after the venue service time.',
  'stf.shifts.error.break_overlap': '[ES] Break overlaps with another break.',
  'stf.shifts.error.shift_service_time': '[ES] Shift must be within the service time',

  // Payments
  'stf.permissions.payments.section_title': '[ES] Payments',
  'stf.permissions.paymentSettings.manage': '[ES] Can Manage Payment Settings',
  'stf.permissions.paymentTerminals.manage': '[ES] Can Manage Payment Terminals',
  'stf.permissions.paymentCredentials.manage': '[ES] Can Manage Payment Credentials',
}

export default staffES
