const paymentsES = {
  'non_integrated.title': '[ES] Non-Integrated Payments',
  'non_integrated.enable_section_title': '[ES] Enable Non-Integrated Payments',
  'non_integrated.enable_section_toggle_description':
    '[ES] Include Non-Integrated Payment options on the POS during checkout',
  'non_integrated.types_section_title': '[ES] Non-Integrated Payment Types',
  'non_integrated.types_section_description':
    '[ES] Configure payment types that will be used when recording Non-Integrated Payments on your POS',
  'non_integrated.types_section_add_button_label': '[ES] Create New Payment Type',
  'non_integrated.types_empty_title': '[ES] There are no Non Integrated Payments to be displayed',
  'non_integrated.validation_error_length': '[ES] Name needs to be under 25 characters.',
  'non_integrated.validation_error_cash_name':
    '[ES] “Cash” is already a default payment method. Using it as a Non-Integrated payment type will cause reporting inconsistencies.',
  'non_integrated.validation_duplicate_name': '[ES] Name is already in use.',
  'non_integrated.create_modal_title': '[ES] Create New Payment Type',
  'non_integrated.input_name_label': '[ES] Name',
  'non_integrated.input_helper_text': '[ES] This name will be displayed on the POS and on receipts',
  'non_integrated.create_modal_cancel_button_label': '[ES] Cancel',
  'non_integrated.create_modal_save_button_label': '[ES] Add Payment Type',
  'non_integrated.alert_added_message': '[ES] Payment type added',
  'non_integrated.alert_deleted_message': '[ES] Payment type deleted',
  'non_integrated.alert_saved_message': '[ES] Saved successfully',
  'non_integrated.alert_error_message':
    '[ES] An error occurred while saving the payment type. {{reason}}',
  'non_integrated.create_modal_update_button_label': '[ES] Update Payment Type',
  'non_integrated.edit_modal_title': '[ES] Edit Payment Type',

  'non_integrated.delete_modal_title': '[ES] Are you sure you want to delete this Payment Type?',
  'non_integrated.delete_modal_confirm_button_label': '[ES] Confirm',
  'non_integrated.delete_modal_cancel_button_label': '[ES] Cancel',
}

export default paymentsES
