const localeToCurrencyMapper = (locale: string): string => {
  switch (locale) {
    case 'en-US':
      return 'USD'
    case 'es-MX':
      return 'MXN'
    case 'en-GB':
      return 'GBP'
    case 'en-CA':
    case 'fr-CA':
    default:
      return 'CAD'
  }
}

export const mapCurrencyCodeToSymbol = (currencyCode = 'USD'): string => {
  let currencySymbol = '$'

  switch (currencyCode) {
    case 'CAD':
    case 'USD':
    case 'MXN':
      currencySymbol = '$'
      break

    case 'GBP':
      currencySymbol = '£'
      break

    case 'EUR':
      currencySymbol = '€'
      break
  }

  return currencySymbol
}

export const formatCurrency = (value: string, lng = 'en-US'): string => {
  const { format } = new Intl.NumberFormat(lng, {
    currency: localeToCurrencyMapper(lng),
    style: 'currency',
  })

  return format(Number(value))
}

export const getCurrencySymbolFromCurrencyCode = (currencyCode?: string): string => {
  const currency = currencyCode ?? localeToCurrencyMapper(navigator.language)

  // Format a value as currency and extract the currency symbol.
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const parts = formatter.formatToParts(0)
  const symbolPart = parts.find((part) => part.type === 'currency')

  return symbolPart ? symbolPart.value : '$'
}
